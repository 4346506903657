// Google Recaptcha v3
grecaptcha.ready(function () {
	$('form.mod-recaptcha').each(function () {
		var object = $(this);
		grecaptcha.execute('6LfjWPMbAAAAANO3mCnHYRlw7SpP9n3Hhe3Aa1wn', { action: 'homepage' }).then(function (token) {
			$('<input>').attr({
				type: 'hidden',
				name: 'g-recaptcha-response',
				value: token
			}).appendTo(object)
		});
	});
});

function resizeIframe(obj) {
	obj.style.height = obj.contentWindow.document.documentElement.scrollHeight + 'px';
}

jQuery(document).ready(function ($) {
	search.load();
});


function hasTouch() {
	return 'ontouchstart' in document.documentElement
		|| navigator.maxTouchPoints > 0
		|| navigator.msMaxTouchPoints > 0;
}

// Hack to remove all hover effects on touch devices
if (hasTouch()) { // remove all :hover stylesheets
	try { // prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match('btn:hover')) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) { }
}

// Mobile hamburger menu
$('.mod-menu').click(function (e) {
	e.preventDefault();

	$(this).toggleClass('mod-active');
	$('body').toggleClass('no-scroll');
	$('#nav, .header-main, #nav > ul.list-nav').toggleClass('mod-active');
	$('header.header-nav').toggleClass('mod-active');

	const hasClass = $(this).hasClass('mod-active');

	$(this).find('span').text(hasClass ? 'Close' : 'Menu');
});

// Prevent dropdowns from clicking on main menu
$(window).on('load resize', function () {
	// If tablet and below
	$('#nav ul.list-nav > li a[href^="#"]').on('click', function (e) {
		const $this = $(this);

		$this.unbind('mouseout');
		$this.toggleClass('mod-dropdown-active');

		e.preventDefault();

		if (window.innerWidth < 1273) {
			$this.next('.sub-dropdown-wrap').toggleClass('mod-active');
		}
	});

});

// Show/hide cookie policy
$("body").on("click", ".section-cookies .btn-accept", function (e) {
	e.preventDefault();

	// If no validation errors
	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 1,
		}
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

// Cookies banner
$(".section-cookies .btn-decline").on("click", (ev) => {
	ev.preventDefault();

	$.ajax({
		method: "POST",
		url: "api/cookies/",
		data: {
			'cookies-accepted': 0,
		}
	}).done(function (response) {
		$(".section-cookies").slideToggle();
	});
});

if ($('#home').length) {
	$(".modal:not(.modal-footer) .sub-btn-close").on("click", (ev) => {
		ev.preventDefault();

		// If no validation errors
		$.ajax({
			method: "POST",
			url: "api/notification/",
		}).done(function (response) {
			$('#home .modal:not(.modal-footer)').fadeOut(500);
			$('body').removeClass('no-scroll');
		});
	});
}

if ($('.footer-main').length) {
	$(".footer-main .sub-middle .btn-subscribe").on("click", (ev) => {
		ev.preventDefault();

		$('.modal-footer').addClass('mod-open');
		$('body').addClass('no-scroll');
	});

	$(".modal-footer .sub-btn-close").on("click", (ev) => {
		$('.modal-footer').removeClass('mod-open');
		$('body').removeClass('no-scroll');
	});
}

// Pops out share modal window
$(".social-share").click(function (e) {
	// Prevents default action
	e.preventDefault();
	// Runs window popup function
	windowPopup($(this).attr("href"), 500, 300);
});

// Ajax contact form processing
$('#contact form').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/contact/',
		data: $(this).serialize()
	}).done(function (response) {

		// if honeypot field is filled in by spambot, do not submit
		if ($('input#Form_Email').val().length != 0) {
			return false;
		}

		// If no errors
		if (response == 'success') {
			// Reset form completely
			$('#contact form .form-element').removeClass('mod-filled');
			$('#contact form').hide().trigger('reset');

			// Show thank you message
			$('#contact .wrap-thanks').fadeIn();

			// Remove any errors that may still be there
			$('#contact form .sub-errors').html('');

			// Hide thank you message after 10 seconds and return to form
			setTimeout(function () {
				$('#contact form').fadeIn();
				$('#contact .wrap-thanks').hide();
			}, 10000);

		}
		else {
			$('#contact form .sub-errors').fadeIn().html(response);
		}
	});
});

// Ajax login form processing
$('#import form.mod-login').submit(function (e) {
	e.preventDefault();

	$.ajax({
		method: 'POST',
		url: 'process/login/',
		data: $(this).serialize()
	}).done(function (response) {
		// If no errors
		if (response != 'success') {
			$('#import form.mod-login .sub-errors').fadeIn().html(response);
			$('#import form.mod-login .form-element').addClass('mod-error');
		}
	});
});

// Add class to inputs when clicked on add effect

if ($('form').length) {
	$('form input, form textarea').focus(function () {
		$(this).parent().addClass('mod-filled');

	}).blur(function () {
		if ($(this).val()) {
			$(this).parent().addClass('mod-filled');
		} else {
			$(this).parent().removeClass('mod-filled');
		}
	});

	// Change style of agreement checkbox on router order form
	$('input[type="checkbox"]').on('change', function () {
		$('.sub-checkbox-wrap').removeClass('mod-checked');

		if ($(this).is(":checked")) {
			$(this).parents('label').addClass('mod-checked');
			$(this).parents('.sub-checkbox-wrap').addClass('mod-checked');
		}
	});
}

// Google Maps

// If div with ID of map exists then run the function
if ($('#map').length) {
	function createMap(el, lat, long, zoom, marker) {
		// Set a center point of the map, can be re-evaluated using `centerMap()`
		const center = { lat: lat, lng: long };

		const mapOptions = {
			zoom: zoom,
			center: center,
			fullscreenControl: false,
		};

		// Create a map instance.
		const melonMap = new MelonMap(document.getElementById(el), mapOptions);

		melonMap.setMarkerImage('assets/svg/icon-map-pin.svg');

		// Marker locations [name, lat, long, zindex]
		const markerLocation = [['McMillan', lat, long, 1]];

		if (marker == true) {
			markerLocation.forEach((markerLocation) => {
				const title = markerLocation[0];

				const location = {
					lat: parseFloat(markerLocation[1]),
					lng: parseFloat(markerLocation[2])
				}

				melonMap.addMarker(location, title);
			});
		}

		// Add a map style
		melonMap.setMapStyle(
			[{ "featureType": "all", "elementType": "geometry.fill", "stylers": [{ "weight": "2.00" }] }, { "featureType": "all", "elementType": "geometry.stroke", "stylers": [{ "color": "#9c9c9c" }] }, { "featureType": "all", "elementType": "labels.text", "stylers": [{ "visibility": "on" }] }, { "featureType": "landscape", "elementType": "all", "stylers": [{ "color": "#f2f2f2" }] }, { "featureType": "landscape", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "landscape.man_made", "elementType": "geometry.fill", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "poi", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "road", "elementType": "all", "stylers": [{ "saturation": -100 }, { "lightness": 45 }] }, { "featureType": "road", "elementType": "geometry.fill", "stylers": [{ "color": "#eeeeee" }] }, { "featureType": "road", "elementType": "labels.text.fill", "stylers": [{ "color": "#7b7b7b" }] }, { "featureType": "road", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }, { "featureType": "road.highway", "elementType": "all", "stylers": [{ "visibility": "simplified" }] }, { "featureType": "road.arterial", "elementType": "labels.icon", "stylers": [{ "visibility": "off" }] }, { "featureType": "transit", "elementType": "all", "stylers": [{ "visibility": "off" }] }, { "featureType": "water", "elementType": "all", "stylers": [{ "color": "#46bcec" }, { "visibility": "on" }] }, { "featureType": "water", "elementType": "geometry.fill", "stylers": [{ "color": "#c8d7d4" }] }, { "featureType": "water", "elementType": "labels.text.fill", "stylers": [{ "color": "#070707" }] }, { "featureType": "water", "elementType": "labels.text.stroke", "stylers": [{ "color": "#ffffff" }] }],
			{ name: 'Styled Map' }
		);

		melonMap.panBy(-10, -130);

	}

	// If the contact page then create the map with the following coordiantes
	if ($('#contact').length) {
		createMap('map', 51.134659, -2.9895848, 10, true);
	}

}

if ($('#event-details').length) {
	var $height = $('#details').outerHeight();

	$('.list-tabs li').click(function () {
		var tab_id = $(this).attr('data-tab');


		$('.list-tabs li').removeClass('mod-active');
		$('.section-event-details-copy .sub-content .sub-copy').removeClass('mod-active');

		$(this).addClass('mod-active');
		$("#" + tab_id).addClass('mod-active');

		if (tab_id == 'tickets') {
			$('.section-event-details-copy').addClass('mod-extend');
			$('#details').css('height', '0px');
		} else {
			$('.section-event-details-copy').removeClass('mod-extend');
			$('#details').css('height', 'auto');
		}
	});

	$('.btn-book-now').click(function (ev) {
		ev.preventDefault();

		var targetScroll = $('.list-tabs').offset().top - 50;

		$('.list-tabs li').data('tab', 'tickets').trigger('click');

		$('html, body').animate({ scrollTop: targetScroll }, 700, 'swing');

	});
}

if ($("#your-visit, #about, #get-involved").length) {
	var currentPage = window.location.href;

	$(".section-navigation select option").each(function () {
		if ($(this).val() == currentPage) {
			$(this).attr("selected", "selected");
		}
	});
}


$('.list-interactions .mod-search').click(function (ev) {
	ev.preventDefault();

	$('.sub-searchbar').toggleClass('mod-active');
	$('.sub-searchbar .searchterm').focus();
});

$('.header-logo-icons .sub-container .sub-searchbar .sub-close').click(function (ev) {
	ev.preventDefault();

	$('.sub-searchbar').toggleClass('mod-active');
	$('.sub-searchbar .searchterm').val('');
	$('.sub-searchbar .sub-results').removeClass('mod-show');
});

// if ($(window).width() >= 1024) {
// 	$('.header-main #nav ul.list-nav li.mod-dropdown > a').click(function (ev) {
// 		ev.preventDefault();

// 		$(this).toggleClass('mod-active');
// 	});
// }

$('#home .list-featured-events, #event-details .sub-gallery').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 10000,
	dots: true,
	draggable: true,
	fade: true,
	infinite: true,
	pauseOnHover: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	speed: 400
});

$('#event-details .list-event-small-gallery').slick({
	arrows: false,
	autoplay: true,
	autoplaySpeed: 7000,
	dots: true,
	draggable: true,
	fade: true,
	infinite: true,
	pauseOnHover: true,
	slidesToScroll: 1,
	slidesToShow: 1,
	speed: 400
});




$(document).ready(function () {

	// Initialize HOME page EVENTS slider
	$('#home .list-events').slick({
		arrows: true,
		autoplay: false,
		draggable: true,
		dots: false,
		infinite: false,
		pauseOnHover: false,
		slidesToScroll: 1,
		slidesToShow: 3,
		speed: 400,
		variableWidth: false,
		prevArrow: $('.nav-slider .sub-prev'),
		nextArrow: $('.nav-slider .sub-next'),
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					arrows: false,
					slidesToScroll: 1,
					slidesToShow: 1,
				}
			}
		]
	});
});

function debounce(func, wait, immediate) {
	var timeout;
	return function () {
		var context = this, args = arguments;
		var later = function () {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


// Search
(function (search, $, undefined) {

	search.load = function () {
		$('body').on('keyup', '.sub-searchbar .searchterm', debounce(function (e) {
			$('.sub-loading').fadeTo(300, 0.5);

			// Clear outputs
			$('.sub-error').html('');
			$('.sub-results ul.list-results').html('');

			$.ajax({
				method: 'POST',
				url: 'api/search/',
				data: { 'searchterm': $(this).val(), lang: $('body').data('lang') }
			}).done(function (response) {

				// Decode json response
				response = JSON.parse(response);

				console.log(response);

				// If we failed to retrieve any results
				if (response[0] == 'failure') {
					// Display error
					$('.sub-error').addClass('mod-show').html(response['message']);
				}
				else {
					// Loop through results
					$.each(response, function (key, value) {
						// Output results as list items
						$('.sub-results ul.list-results').append('<li><a class="title" href="' + value['link'] + '">' + value['title'] + '</a><span class="category">' + value['type'] + '</span></li>');
					});

					$('.sub-results').addClass('mod-show');
				}
			});

			//hide loading overlay
			$('.sub-loading').fadeOut(300);
		}, 250));
	};
}(window.search = window.search || {}, jQuery));